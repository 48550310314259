import {
  createApp,
} from "vue";

import router from "./Router/Router";

import App from "./App/App.vue";
import store from "./store";

import AFiltersPlugin from "aloha-vue/src/plugins/AFiltersPlugin";
import AHttpPlugin from "aloha-vue/src/plugins/AHttpPlugin";
import AI18nPlugin from "aloha-vue/src/plugins/AI18nPlugin";
import AInputNumberPlugin from "aloha-vue/src/plugins/AInputNumberPlugin";
import AMobilePlugin from "aloha-vue/src/plugins/AMobilePlugin";
import AModalPlugin from "aloha-vue/src/plugins/AModalPlugin";
import AIconPlugin from "aloha-vue/src/plugins/AIconPlugin";
import ASelectPlugin from "aloha-vue/src/plugins/ASelectPlugin";
import ASpinnerPlugin from "aloha-vue/src/plugins/ASpinnerPlugin";
import {
  PuxTablePlugin,
} from "pux-table";
import mainPuxTable from "./mainPuxTable";

import ASafeHtml from "aloha-vue/src/directives/ASafeHtml";

import mainHttp from "./mainHttp";
import {
  addFilters,
} from "./mainFilters";
import {
  initSentry,
} from "./mainSentry";
import icons from "../icons";
import {
  addErrorListenerForCodeSplitting,
} from "./global/functions/ErrorListenerForCodeSplitting";
import {
  changeLinkToRoute,
} from "./global/functions/changeLinkToRoute";
import {
  replaceText,
} from "./global/functions/utils";
import moment from "moment";
import "moment/locale/de";

moment.locale("de");

const APP = createApp(App);

APP.config.globalProperties.$statusAdmin = false;

APP.use(AI18nPlugin, {}, "de-de", {
  replaceText: replaceText,
});
APP.use(AMobilePlugin, {
  breakpoint: 991,
});
APP.use(AModalPlugin, {
  propsDefault: {
    closeButtonClass: "a_btn a_btn_link",
  },
});
APP.use(ASpinnerPlugin, {
  propsDefault: {
    class: "loading_container__msg_container__spinner spinner",
    safeHtml: `<span class="bounce1"></span><span class="bounce2"></span><span class="bounce3"></span>`,
  },
});

APP.use(AHttpPlugin, mainHttp);
APP.use(AIconPlugin, { icons });
APP.use(AInputNumberPlugin, {
  propsDefault: {
    isLabelFloat: true,
  },
});
APP.use(AFiltersPlugin, {
  config: {
    headerBottomGroups: [
      {
        props: {},
        children: [
          {
            component: "addFilterSelect",
            props: {
              keyGroup: "group",
            },
          },
          {
            component: "buttonSearch",
            props: {},
          },
        ],
      },
    ],
  },
});
APP.use(ASelectPlugin, {
  propsDefault: {
    caretIcon: "chevron-down",
    keyGroup: "group",
    sortOrderGroup: "asc",
  },
});
APP.use(PuxTablePlugin.install, mainPuxTable);

// APP.config.unwrapInjectedRef = true;
APP.directive("SafeHtml", ASafeHtml);
initSentry({ app: APP, router });
APP.use(store);
APP.use(router).mount("#app");

addFilters();
addErrorListenerForCodeSplitting();
changeLinkToRoute(router);
