const AenderungsanzeigeDetails = () => import(/* webpackChunkName: "AenderungsanzeigeDetails" */ "../../../views/HSK/AenderungsanzeigeDetails/AenderungsanzeigeDetails.vue");
const AenderungsanzeigeList = () => import(/* webpackChunkName: "AenderungsanzeigeList" */ "../../../views/HSK/AenderungsanzeigeList/AenderungsanzeigeList.vue");
const AkkreditierteHochschuleDetails = () => import(/* webpackChunkName: "AkkreditierteHochschuleDetails" */ "../../../Mandanten/ar/AkkreditierteHochschule/AkkreditierteHochschuleDetails/AkkreditierteHochschuleDetails.vue");
const AkkreditierteHochschuleList = () => import(/* webpackChunkName: "AkkreditierteHochschuleList" */ "../../../Mandanten/ar/AkkreditierteHochschule/AkkreditierteHochschuleList/AkkreditierteHochschuleList.vue");
const AkkreditierteStudiengaengeDetails = () => import(/* webpackChunkName: "AkkreditierteStudiengaengeDetails" */ "../../../Mandanten/ar/AkkreditierteStudiengaenge/AkkreditierteStudiengangDetails/AkkreditierteStudiengangDetails.vue");
const AkkreditierteStudiengaengeList = () => import(/* webpackChunkName: "AkkreditierteStudiengaengeList" */ "../../../Mandanten/ar/AkkreditierteStudiengaenge/AkkreditierteStudiengaengeList/AkkreditierteStudiengaengeList.vue");
const AkkreditierungsantraegeList = () => import(/* webpackChunkName: "AkkreditierungsantraegeList" */ "../../../Mandanten/ar/Akkreditierungsantraege/AkkreditierungsantraegeList/AkkreditierungsantraegeList.vue");
const SystemakkreditierungsantraegeList = () => import(/* webpackChunkName: "SystemakkreditierungsantraegeList" */ "../../../Mandanten/ar/Systemakkreditierungsantraege/SystemakkreditierungsantraegeList/SystemakkreditierungsantraegeList.vue");
const ArDatenbank = () => import(/* webpackChunkName: "ArDatenbank" */ "../../../Mandanten/ar/ArDatenbank/ArDatenbank.vue");
const DeqarExportList = () => import(/* webpackChunkName: "DeqarExportList" */ "../../../Mandanten/ar/DeqarExportList/DeqarExportList.vue");
const HSKStudiengangLandingpage = () => import(/* webpackChunkName: "HSKStudiengangLandingpage" */ "../../../Mandanten/ar/HSKStudiengangLandingpage/HSKStudiengangLandingpage.vue");
const HochschuleDetails = () => import(/* webpackChunkName: "HochschuleDetails" */ "../../../Mandanten/ar/Hochchule/HochschuleDetails/HochschuleDetails.vue");
const HochschuleList = () => import(/* webpackChunkName: "HochschuleList" */ "../../../Mandanten/ar/Hochchule/HochschuleList/HochschuleList.vue");
const ProgrammakkreditierungDetails = () => import(/* webpackChunkName: "ProgrammakkreditierungDetails" */ "../../../Mandanten/ar/Programmakkreditierung/ProgrammakkreditierungDetails/ProgrammakkreditierungDetails.vue");
const ProgrammakkreditierungList = () => import(/* webpackChunkName: "ProgrammakkreditierungList" */ "../../../Mandanten/ar/Programmakkreditierung/ProgrammakkreditierungList/ProgrammakkreditierungList.vue");
const StudiengangDetails = () => import(/* webpackChunkName: "StudiengangDetails" */ "../../../Mandanten/ar/Studiengang/StudiengangDetails/StudiengangDetails.vue");
const StudiengangList = () => import(/* webpackChunkName: "StudiengangList" */ "../../../Mandanten/ar/Studiengang/StudiengangList/StudiengangList.vue");
const SystemakkreditierungDetails = () => import(/* webpackChunkName: "SystemakkreditierungDetails" */ "../../../Mandanten/ar/Systemakkreditierung/SystemakkreditierungDetails/SystemakkreditierungDetails.vue");
const SystemakkreditierungList = () => import(/* webpackChunkName: "SystemakkreditierungList" */ "../../../Mandanten/ar/Systemakkreditierung/SystemakkreditierungList/SystemakkreditierungList.vue");

export default [
  {
    path: "/programmakkreditierung/",
    name: "root.programmakkreditierung",
    component: ProgrammakkreditierungList,
    meta: {
      permissions: [
        "programmakkreditierung.view",
      ],
    },
  },
  {
    path: "/programmakkreditierung/:id/",
    name: "root.programmakkreditierung.detail",
    component: ProgrammakkreditierungDetails,
    meta: {
      permissions: [
        "programmakkreditierung.view",
      ],
    },
  },
  {
    path: "/systemakkreditierung/",
    name: "root.systemakkreditierung",
    component: SystemakkreditierungList,
    meta: {
      permissions: [
        "systemakkreditierung.view",
      ],
    },
  },
  {
    path: "/systemakkreditierung/:id/",
    name: "root.systemakkreditierung.detail",
    component: SystemakkreditierungDetails,
    meta: {
      permissions: [
        "systemakkreditierung.view",
      ],
    },
  },
  {
    path: "/akkrstudiengaenge/",
    name: "root.akkrstudiengang",
    component: AkkreditierteStudiengaengeList,
  },
  {
    path: "/akkrstudiengaenge/:id/",
    name: "root.akkrstudiengang.detail",
    component: AkkreditierteStudiengaengeDetails,
  },
  {
    path: "/akkrhochschulen/",
    name: "root.akkrhochschule",
    component: AkkreditierteHochschuleList,
  },
  {
    path: "/akkrhochschulen/:id/",
    name: "root.akkrhochschule.detail",
    component: AkkreditierteHochschuleDetails,
  },
  {
    path: "/akkreditierungsantraege/",
    name: "root.akkreditierungsantrag",
    component: AkkreditierungsantraegeList,
    meta: {
      permissions: [
        "akkreditierungsantrag.list",
      ],
    },
  },
  {
    path: "/systemakkreditierungsantraege/",
    name: "root.systemakkreditierungsantrag",
    component: SystemakkreditierungsantraegeList,
    meta: {
      permissions: [
        "systemakkreditierungsantrag.list",
      ],
    },
  },
  {
    path: "/deqar_exporte/",
    name: "root.deqar_export",
    component: DeqarExportList,
  },
  {
    path: "/datenbank/",
    name: "root.datenbank",
    component: ArDatenbank,
  },
  {
    path: "/aenderungsanzeige/",
    name: "root.aenderungsanzeige",
    component: AenderungsanzeigeList,
    meta: {
      permissions: [
        "aenderungsanzeige.view",
      ],
    },
  },
  {
    path: "/aenderungsanzeige/:id/",
    name: "root.aenderungsanzeige.detail",
    component: AenderungsanzeigeDetails,
    meta: {
      permissions: [
        "aenderungsanzeige.view",
      ],
    },
  },
  {
    path: "/hochschule/",
    name: "root.hochschule",
    component: HochschuleList,
    meta: {
      permissions: [
        "hochschule.view",
      ],
    },
  },
  {
    path: "/hochschule/:id/",
    name: "root.hochschule.detail",
    component: HochschuleDetails,
    meta: {
      permissions: [
        "hochschule.view",
      ],
    },
  },
  {
    path: "/studiengang/",
    name: "root.studiengang",
    component: StudiengangList,
    meta: {
      permissions: [
        "studiengang.view",
      ],
    },
  },
  {
    path: "/studiengang/:id/",
    name: "root.studiengang.detail",
    component: StudiengangDetails,
    meta: {
      permissions: [
        "studiengang.view",
      ],
    },
  },
  {
    path: "/hsk/studiengang/",
    name: "root.hsk_studiengang_landingpage",
    component: HSKStudiengangLandingpage,
  },
];
